import React, {FC, useCallback} from "react";
import Link from "next/link";
import {url} from "../../../../services/utils";
import {propsTypeForProductImage as IProps} from "./types"
import cls from "../../header.module.scss"
import {domainUrl} from "../../../../helper";
import Image from "next/image";
import {useSelector} from "react-redux";
import {IState} from "../../../../types/state";

const ProductImage: FC<IProps> = ({length, item}) => {

    const dbName = useSelector((state: IState) => state.general.dbName)

    const sourceUrl = useCallback(() => {
        if (length && item.product.product_images?.[0].path) {
            return domainUrl(`${dbName}/cache/medium/${item.product.product_images?.[0].path}`)
        }
        return `${item.product.base_image.small_image_url}`
    }, [length])
    return (
        <div className={`${cls["product-image"]} ${cls["dropcart__product-image"]}`}>
            <Link
                href={url.product(item.product)}
                className={cls["product-image__body"]}
            >
                <a>
                    <Image
                        src={sourceUrl()}
                        alt={item.product.name}
                        height={56}
                        width={56}
                        placeholder="blur"
                        priority
                        blurDataURL={`/zega/blur1.webp`}
                    />
                </a>

            </Link>
        </div>
    )
}

export default ProductImage;