import React from "react";
import {FormattedMessage} from "react-intl";
import { FailSvg } from "../../../../svg"
import cls from "../../header.module.scss"

const NotAvailable = () => (
    <span className={cls["faild-toast-fms"]}>
        <FailSvg/>
        <FormattedMessage
            id="sign-or-register"
            defaultMessage="This product is not available"
        />
    </span>
)

export default NotAvailable;
